import { gs } from "../../styles"
import { FillImage } from "../FillImage"

const content = [
`For information, please contact Dr. Steve Sider 
(ssider@wlu.ca).`
]

export const ContactMenu = () => {
	return (<>
		<div style={{ ...gs.left, margin: '0.5rem' }}>
			<FillImage style={{ height: '1.3rem', width: '1.3rem', marginRight: '0.5rem' }}src='/icons/menu-account.png' alt='Settings' />
			<h5 style={gs.menuTitleText}>Contact</h5>
		</div>
		<div style={{ margin: '0.5rem' }}>
			{content.map((c, i) => (
				<p style={gs.menuContentText}>{c}</p>
			))}
		</div>
	</>)
}

