import { useRef } from "react"
import { colors, createStyles, gs } from "../../styles"
import { VoiceToText } from "../../utils/audio"
import { FillImage } from "../FillImage"
import { HiddenFileUpload } from "./HiddenFileUpload"

export const Mode1Placeholder = (props: {
	isRecording: boolean
	voiceToText: VoiceToText,
	onFileUpload: (files: FileList) => void
}) => {
	const fileUploadRef = useRef<HTMLInputElement>(null)
	return (
		<div style={s.container}>
			<div style={{
				backgroundColor: colors.primary,
				width: '10rem',
				height: '10rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '50%',
				overflow: 'hidden',
			}}>
				<FillImage style={{ width: '8rem', height: '5.6rem' }} src='/images/logo.png' alt='Logo' />
			</div>
			<h3 style={s.title}>How can I help you today?</h3>
			<p style={s.subTitle}>Please consider checking information, AI is only to be used as a support tool.</p>
			<p style={s.subTitle}>Do not share any personal information.</p>
			<div style={gs.centered}>
				{props.voiceToText.enabled && <button style={{ ...s.button, marginRight: '2rem' }} onClick={props.isRecording ? props.voiceToText.stop : props.voiceToText.start}>
					<img src='/icons/microphone.png' alt="Start Voice to Text" style={s.micIcon} />
					<p style={s.label}>Voice to Text</p>
				</button>}
				<HiddenFileUpload inputRef={fileUploadRef} onFileChange={props.onFileUpload} />
				<button style={s.button} onClick={() => fileUploadRef.current?.click()}>
					<img src='/icons/upload-file.png' alt="Start Voice to Text" style={s.uploadIcon} />
					<p style={s.label}>Upload File</p>
				</button>
			</div>
			<p style={s.subTitle}>Only .pdf, .docx and .txt files accepted</p>
		</div>
	)
}

const s = createStyles({
	container: {
		...gs.column,
		width: 'calc(100% - 2rem)',
		padding: '1rem',
	},
	title: {
		fontSize: '1.2rem',
		fontWeight: 400,
		color: colors.grey,
		margin: '1rem 0',
	},
	subTitle: {
		fontSize: '0.9rem',
		fontWeight: 300,
		color: colors.grey,
		marginBottom: '0.5rem',
	},
	button: {
		...gs.column,
		width: '6rem',
		border: `2px solid ${colors.lightGrey}`,
		borderRadius: '0.4rem',
		padding: '0.5rem',
	},
	micIcon: {
		height: '2rem',
	},
	uploadIcon: {
		height: '2rem',
	},
	label: {
		fontSize: '0.7rem',
		fontWeight: 300,
		color: colors.grey,
	}
})