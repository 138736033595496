import { useEffect, useState } from "react"
import { createStyles, gs, colors } from "../styles"
import { getAuthorization } from "../data/auth"
import { SessionStorage } from "../utils/sessionStorage"
import { useDisplay } from "../hooks"

export const LoadingView = (props: {
	next: () => void
}) => {
	const { mobile } = useDisplay()
	useEffect(() => {
		if (SessionStorage.get('token')) {
			props.next()
		}
	}, [])
	const [password, setPassword] = useState('')
	const tryGetAuth = async () => {
		const tokenRes = await getAuthorization(password)
		if (tokenRes.success) {
			SessionStorage.set('token', tokenRes.data)
			props.next()
		} else {
			alert("Invalid Password")
		}
	}
	const loadingLogoStyles = mobile ? {
		width: 'calc(100% - 2rem)',
		margin: '5rem 1rem',
	 } : {
		width: '80%',
		margin: '5rem',
	 }
	return (
		<div style={s.container}>
			<img src={'/images/loading-logo.png'} style={loadingLogoStyles} alt="Loading..." />
			<p>Enter password:</p>
			<input 
				type="password" 
				style={s.passwordInput}
				value={password} 
				onChange={(e) => setPassword(e.target.value)} 
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						tryGetAuth()
					}
				}}
			/>
			<button onClick={tryGetAuth} style={s.submitButton}>Submit</button>
		</div>
	)
}

const s = createStyles({
	container: {
		...gs.column,
		backgroundColor: colors.primary,
		width: '100%',
		height: '100%',
	},
	passwordInput: {
		margin: '1rem 0',
		padding: '0.25rem',
		fontSize: '1rem',
		borderRadius: '0.5rem',
	},
	submitButton: {
		backgroundColor: 'white',
		padding: '0.5rem 1rem',
		fontSize: '1rem',
		borderRadius: '0.5rem',
	},
})
