import { colors, createStyles } from '../../styles'
import { Mode1MessageType, Mode2MessageType } from '../../types/messages'
import { FillImage } from '../FillImage'
import { BotGeneratePrompt, BotMCQuestion, QuestionTypeSelect } from './BotPrompt'
import { LoadingDots } from './LoadingDots'
import { MessageFeedback } from './MessageFeedback'

export const Message = (props: {
	message: Mode1MessageType | Mode2MessageType
	loading?: undefined
	mode: 1 | 2
} | {
	loading: true
	message?: undefined
	mode: 1 | 2
}): JSX.Element => {
	const message = props.message
	const feedbackEnabled = message?.message_id && message?.type.includes('bot')
	
	const messageStyles = (message?.type.includes('bot') || props.loading) ? {
		alignSelf: 'flex-start',
		justifyContent: 'flex-start',
	} : {
		alignSelf: 'flex-end',
		justifyContent: 'flex-end',
	}
	if (props.loading) {
		return (
			<div style={{ ...s.message, ...messageStyles }}>
				<FillImage style={s.botAccountIcon} src='/icons/bot.png' alt='Bot' />
				<div style={{ 
					...s.bubble, 
					backgroundColor: colors.lightGrey,
					borderRadius: '0.5rem 0.5rem 0.5rem 0',
					padding: '0.4rem 0.8rem',
				}} className="message-bubble">
					<LoadingDots />
				</div>
			</div>
		)
	}
	
	if (!message) return <></>

	let backgroundColor = colors.tertiary
	let borderRadius = '0.5rem 0.5rem 0 0.5rem'

	if (message.type === 'bot_select') {
		borderRadius = '1rem'
	} else if (message.type.includes('bot')) {
		backgroundColor = colors.lightGrey
		borderRadius = '0 1rem 1rem 1rem'
	} else if (message.type === 'user') {
		backgroundColor = colors.secondary
		borderRadius = '1rem 1rem 0 1rem'
	} else if (message.type === 'failed_file') {
		backgroundColor = colors.error
		borderRadius = '0.5rem 0.5rem 0 0.5rem'
	}
	
	return (
		<div style={{ ...s.message, ...messageStyles }}>
			{message.type.includes('bot') && (
				<FillImage style={s.botAccountIcon} src='/icons/bot.png' alt='Bot' />
			)}
			<div style={{ ...s.bubble, backgroundColor, borderRadius }} className="message-bubble">
				{message.type.includes('file') ? (<>
					<p style={s.filename}>{message.filename}</p>
					<p style={s.filename}>{message.board_name}</p>
					{message.type === 'failed_file' && <p style={s.fileError}>Upload Failed</p>}
				</>) : message.type === 'bot_select' ? (
					<QuestionTypeSelect />
				) : message.type === 'bot_prompt' ? (
					<BotGeneratePrompt message={message}/>
				) : message.type === 'bot_question_oeq' ? (
					<p style={s.bubbleText}>{message.question?.question}</p>
				) : message.type === 'bot_question_mcq' ? (
					<BotMCQuestion message={message} />
				) : (message.message?.split('\n').map((line, index) => (
					<p style={s.bubbleText} key={index}>{line}</p>
				)))}
			</div>
			{feedbackEnabled && (
				<MessageFeedback 
					message={message}
					mode={props.mode}
				/>
			)}
		</div>
	)
}

const s = createStyles({
	message: {
		maxWidth: '90%',
		display: 'flex',
		margin: '0.4rem 0',
		overflow: 'visible',
	},
	botAccountIcon: {
		height: '1.5rem',
		width: '1.5rem',
		minWidth: '1.5rem',
		padding: '0.4rem',
		borderRadius: '50%',
		backgroundColor: colors.tertiary,
		marginRight: '0.5rem',
	},
	bubble: {
		padding: '0.8rem',
	},
	bubbleText: {
		fontSize: '0.9rem',
		textAlign: 'left',
		wordWrap: 'break-word',
		maxWidth: '100%',
		fontWeight: 300,
	},
	filename: {
		fontSize: '0.9rem',
		textAlign: 'left',
		wordWrap: 'break-word',
		maxWidth: '100%',
		fontWeight: 500,
	},
	fileError: {
		fontSize: '0.6rem',
		textAlign: 'left',
		color: colors.errorDark,
		wordWrap: 'break-word',
		maxWidth: '100%',
		fontWeight: 400,
	}
})


