import { CSSProperties } from "react"
import { gs } from "../styles"

export const FillImage = (props: {
	src: string,
	style?: CSSProperties
	alt?: string
}) => {
	return (
		<div style={props.style ?? gs.buttonIcon}>
			<img src={props.src} alt={props.alt} style={gs.fillImage} />
		</div>
	)
}
