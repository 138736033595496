import { DataResponse, DataResponseType } from "../types/data"
import { DirectoryFile } from "../types/files"
import { SessionStorage } from "../utils/sessionStorage"
import { API_URL } from "./api"

export const uploadFiles = async (chatID: string | undefined, files: File[], mode: 1 | 2): Promise<DataResponseType<string>> => {
	try {
		const formData = new FormData()
		// formData.append('files', [file])
		files.forEach((file, index) => {
			formData.append('files', file, file.name);
		});

		if (chatID) formData.append('chat_id', chatID)
		const response = await fetch(`${API_URL}/upload_documents`, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		if (response.ok) {
			const result = await response.json()
			return DataResponse.success(result.chat_id)
		} else {
			return DataResponse.warning('Failed to upload file.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to upload file.')
	}
}

export const getDirectoryFileList = async (): Promise<DirectoryFile[]> => {
	await new Promise(resolve => setTimeout(resolve, 1000))
	const filesResponse = await fetch(`${API_URL}/directory/files`, {
		headers: {
			'Authorization': `Bearer ${SessionStorage.get('token')}`
		}
	})
	if (filesResponse.ok) {
		const files = await filesResponse.json() as DirectoryFile[]
		files.forEach(f => {
			f.type = f.file_name.includes('.pdf') ? 'PDF' : f.file_name.includes('.doc') ? 'DOCX' : 'TXT'
		})
		return files
	} else {
		return []
	}
}
