import { CSSProperties } from "react"

export type StyleType = { [key: string]: CSSProperties }

export const createStyles = <T>(styles: T & StyleType): {
	[K in keyof T]: CSSProperties	
} => {
	return styles
}

export const gs = createStyles({
	centered: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	spread: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	left: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
	},
	leftCol: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexDirection: 'column',
		width: '100%',
	},
	fillImage: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	buttonIcon: {
		width: '1.2rem',
		height: '1.2rem',
		marginRight: '0.5rem',
	},
	buttonText: {
		color: 'white',
		fontSize: '1rem',
	},
	menuTitleText: {
		color: 'white',
		fontSize: '1.1rem',
		fontWeight: 300,
	},
	menuContentText: {
		color: 'white',
		fontSize: '1rem',
		fontWeight: 300,
		textAlign: 'left',
		marginBottom: '0.5rem'
	}
})