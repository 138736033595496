import { useEffect, useState } from "react"
import { Header } from "../components/Header"
import { colors, createStyles, gs} from "../styles"
import { DirectoryFile } from "../types/files"
import { LetteredList, getLetteredList } from "../utils/files"
import { getDirectoryFileList } from "../data/files"
import { FillImage } from "../components/FillImage"
import { useDisplay, useFiles, useMode1Data, useMode2Data } from "../hooks"
import { FilePreviewModal } from "../components/modal/FilePreviewModal"


export const DirectoryView = (props: {
	onSelect: (files: DirectoryFile[]) => void
}) => {
	const { view } = useDisplay()
	const [letteredFileList, setLetteredFileList] = useState<LetteredList<DirectoryFile> | null>(null)
	const [selectedFiles, setSelectedFiles] = useState<DirectoryFile[]>([])
	const selectedFileIds = selectedFiles.map((file) => file.id)
	const { directoryFiles, setDirectoryFiles } = useFiles()
	const [previewFile, setPreviewFile] = useState<DirectoryFile | null>(null)
	const [search, setSearch] = useState('')
	const mode1Data = useMode1Data()
	const mode2Data = useMode2Data()
	const mode1 = view === 'mode1_directory'
	const mode2 = view === 'mode2_directory'
	const schoolBoard = mode1 ? mode1Data.schoolBoard : mode2 ? mode2Data.schoolBoard : undefined
	const [directorySchoolBoard, setDirectorySchoolBoard] = useState('wcdsb')

	useEffect(() => {
		if (directoryFiles !== null) return
		const getDirectoryFiles = async () => {
			const newDirectoryFiles = await getDirectoryFileList()
			setDirectoryFiles(newDirectoryFiles)
		}
		getDirectoryFiles()
	}, [directoryFiles, setDirectoryFiles])

	useEffect(() => {
		if (directoryFiles === null) return
		const getLetteredFileList = async () => {
			const filteredDirectoryFiles = directoryFiles.filter((file) => {
				if (!file.board_name.includes(schoolBoard ? schoolBoard : directorySchoolBoard)) {
					return false
				}
				if (search.length > 0 && !file.file_name.toLowerCase().includes(search.toLowerCase())) {
					return false
				}
				return true
			})
			const newLetteredFileList = await getLetteredList(filteredDirectoryFiles, 'file_name')
			setLetteredFileList(newLetteredFileList)
		}
		getLetteredFileList()
	}, [directoryFiles, schoolBoard, directorySchoolBoard, search])
	const selectFile = (file: DirectoryFile) => {
		const fileIndex = selectedFileIds.indexOf(file.id)
		if (fileIndex === -1) {
			setSelectedFiles([...selectedFiles, file])
		} else {
			selectedFiles.splice(fileIndex, 1)
			setSelectedFiles([...selectedFiles])
		}
	}

	const onSchoolBoardChange = (!mode1 && !mode2) ? (newSchoolBoard: string) => {
		setDirectorySchoolBoard(newSchoolBoard)
	} : undefined

	return (
		<div style={s.container}>
			<Header setSchoolBoard={onSchoolBoardChange}/>
			<div style={s.subHeader}>
				<h3 style={s.title}>Directory</h3>
				<div style={s.inputContainer}>
					<img  style={s.searchIcon} src={'/icons/search.png'} alt="Search" />
					<input
						type="text"
						placeholder="Search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						style={s.input}
					/>
				</div>
			</div>
			<div style={s.content}>
				{letteredFileList === null && (
					<p>Loading...</p>
				)}
				{letteredFileList?.map((letterItem) => (
					<div key={letterItem.letter} style={s.letterContainer}>
						<h6 style={s.letter}>{letterItem.letter}</h6>
						{letterItem.items.map((file, i) => {
							const selected = selectedFileIds.includes(file.id)
							const fileStyle = selected ? { ...s.file, backgroundColor: colors.secondary } : s.file
							return (<div 
								key={i} 
								style={fileStyle}  
								onClick={() => selectFile(file)}
								className="directory-item"
							>
								<div style={gs.leftCol} className='hover-opacity'>	
									<p style={s.fileName}>{file.file_name} - {file.board_name}</p>
									<p style={s.fileType}>{file.type.toUpperCase()} file</p>
								</div>
								{selected && <FillImage style={s.closeIcon} src={'/icons/close.png'} alt="Close" />}
								<button 
									style={s.previewButton}
									onClick={(e) => {
										e.stopPropagation()
										console.log(file.s3_url)
										setPreviewFile(file)
									}}
								>Preview</button>
							</div>)
						})}
					</div>
				))}
			</div>
			{selectedFiles.length > 0 && view !== 'view_directory' && (
				<div style={s.selectFilesButton}>
					<div 
						style={{ ...gs.centered, cursor: 'pointer' }}
						onClick={() => props.onSelect(selectedFiles)}
						className="send-directory-files-button"
					>
						<p style={s.selectedButtonText}>{selectedFileIds.length} Selected</p>
						<FillImage  style={s.closeIcon} src={'/icons/send.png'} alt="Close" />
					</div>
				</div>
			)}
			{previewFile && <FilePreviewModal 
				file={previewFile} 
				close={() => setPreviewFile(null)}
			/>}
		</div>
	)
}
  
const s = createStyles({
	container: {
		...gs.column,
		width: '100%',
		height: '100%',
	},
	subHeader: {
		...gs.leftCol,
		backgroundColor: colors.background,
		borderTop: `1px solid ${colors.lightGrey}`,
		padding: '2rem 1rem 0',
		width: 'calc(100% - 2rem)',
	},
	inputContainer: {
		...gs.left,
		width: 'calc(100% - 2rem)',
		margin: '1rem 0',
		backgroundColor: '#D9D9D961',
		borderRadius: '0.5rem',
	},
	input: {
		backgroundColor: 'transparent',
		border: 'none',
		textAlign: 'left',
		outline: 'none',
	},
	searchIcon: {
		height: '0.8rem',
		margin: '0.5rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		padding: '2rem 1rem',
		alignItems: 'flex-start',
		backgroundColor: colors.background,
		width: 'calc(100% - 2rem)',
		height: 'calc(100% - 4rem)',
		overflowY: 'scroll',
	},
	title: {
		fontWeight: 400,
		fontSize: '1.4rem'
	},
	letterContainer: {
		margin: '0.5rem 0',
		width: 'calc(100%)'
	},
	letter: {
		textAlign: 'left',
		fontSize: '1.1rem',
		fontWeight: 400,
	},
	file: {
		...gs.spread,
		width: 'calc(100% - 2rem)',
		border: '1px solid #2222221A',
		borderRadius: '0.5rem',
		padding: '0.8rem 1rem',
		margin: '1rem 0',
		cursor: 'pointer'
	},
	previewButton: {
		backgroundColor: colors.primary,
		borderRadius: '0.5rem',
		padding: '0.5rem 1rem',
		fontSize: '0.8rem',
		color: 'white',
		fontWeight: 300,
		marginLeft: '1rem'
	},
	fileName: {
		textAlign: 'left',
		wordBreak: 'break-all'
	},
	fileType: {
		textAlign: 'left',
		fontSize: '0.8rem',
		color: '#222222',
		fontWeight: 300
	},
	closeIcon: {
		width: '1.5rem',
		height: '1.5rem',
	},
	selectFilesButton: {
		...gs.centered,
		paddingLeft: '0.8rem',
		position: 'absolute',
		bottom: '1rem',
		right: '1rem',
		backgroundColor: colors.secondary,
		borderRadius: '0.5rem',
	},
	selectedButtonText: {
		fontWeight: 300,
		margin: '0.5rem 0'
	}
})
