import { useState } from "react"
import { colors, createStyles, gs } from "../../styles"
import { useDisplay, useFiles, useMode1Data, useMode2Data } from "../../hooks"
import { evaluateQuestion, getMode1Response, getUserPromptResponse } from "../../data/messages"
import { VoiceToText } from "../../utils/audio"
import { UploadModal } from "../modal/UploadModal"
import { FillButton } from "../FillButton"
import { failureMessage, getDirectoryFilesHistory, getFileHistoryType, getLastQuestion, userMessageType } from "../../utils/messages"

export const ChatInput = (props: {
	mode: 1 | 2,
	voiceToText: VoiceToText,
	isRecording: boolean,
	onFileUpload: (files: FileList) => void
}) => {
	const { mobile } = useDisplay()
	const { directoryFiles } = useFiles()
	const mode1Data = useMode1Data()
	const mode2Data = useMode2Data()
	const mode1 = props.mode === 1
	const {
		setAwaitingResponse, 
		awaitingResponse, 
		input, 
		setInput, 
		setChatID, 
		chatID,
		messages,
		schoolBoard,
	} = mode1 ? mode1Data : mode2Data
	const [expanded, setExpanded] = useState(false)
	const [showUploadModal, setShowUploadModal] = useState(false)
	const rows = expanded ? 3 : 1
	const maxSingleLineTextLength = mobile ? 30 : 60
	const addedButtonStyle = awaitingResponse ? {
		filter: 'greyscale(1)'
	} : {}

	const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
		if (awaitingResponse) return
		setInput(e.target.value)
		if (e.target.value.length > maxSingleLineTextLength && !expanded) {
			setExpanded(true)
		} else if (e.target.value.length <= maxSingleLineTextLength && expanded) {
			setExpanded(false)
		}
	}

	const fileMode = getFileHistoryType(messages)

	const trySendMessage = async () => {
		if (input.trim().length === 0) return
		setAwaitingResponse(true)
		setInput('')
		setExpanded(false)
		
		if (mode1) {
			let newMessages = mode1Data.addMessage(mode1Data.messages, { message: input, type: 'user' })
			try {
				const directoryFilesHistory = (fileMode === 'directory' && directoryFiles !== null) ? 
					getDirectoryFilesHistory(messages, directoryFiles) : undefined
				const res = await getMode1Response(input, schoolBoard, chatID, fileMode, directoryFilesHistory)
				if (!res.success) throw Error(res.error.message)
				setChatID(res.data.chat_id)
				newMessages = mode1Data.addIdToLastMessage(newMessages, res.data.request_id as number)
				mode1Data.addMessage(newMessages, {
					type: 'bot',
					message: res.data.response,
					message_id: res.data.response_id
				})
			} catch (e) {
				console.error(e)
				mode1Data.addMessage(newMessages, failureMessage)
			}
			
		} else {
			const modeTypeUserMessageType = userMessageType(mode2Data.messages)
			if (!modeTypeUserMessageType) return
			let newMessages = mode2Data.addMessage(mode2Data.messages, { message: input, type: modeTypeUserMessageType })
			try {
				if (modeTypeUserMessageType === 'user_prompt') {
					const res = await getUserPromptResponse(input, chatID)
					if (!res.success) throw Error(res.error.message)
					setChatID(res.data.chat_id)
					newMessages = mode2Data.addIdToLastMessage(newMessages, res.data.request_id as number)
					mode2Data.addMessage(newMessages, {
						type: 'bot_prompt',
						message: res.data.response,
						message_id: res.data.response_id,
					})
				} else if (modeTypeUserMessageType === 'answer') {
					const question = getLastQuestion(mode2Data.messages)
					if (!question) return
					const res = await evaluateQuestion(
						input,
						question.answer,
						question.question,
						question.id,
						question.type,
						chatID
					)
					if (!res.success) throw Error(res.error.message)
					const message = res.data.response
					newMessages = mode2Data.addIdToLastMessage(newMessages, res.data.request_id as number)
					if (mode2Data.questions.length === 0) {
						mode2Data.addMessage(newMessages, {
							type: 'bot',
							message,
							message_id: res.data.response_id,
						})
					} else {
						const newQuestion = mode2Data.questions.pop()
						mode2Data.setQuestions([...mode2Data.questions])
						const type = 'bot_question_' + newQuestion?.type  as 'bot_question_mcq' | 'bot_question_oeq'
						mode2Data.addMessages(newMessages, [{
							type: 'bot',
							message,
							message_id: res.data.response_id,
						}, {
							type,
							question: newQuestion,
							message_id: newQuestion?.id,
						}])
					}
				}
			} catch (e) {
				console.error(e)
				mode2Data.addMessage(newMessages, failureMessage)
			}
		}
		setAwaitingResponse(false)
	}

	return (
		<div style={s.container} className='chat-input'>
			{(mode1 || !fileMode) && (
				<FillButton 
					style={s.addIcon}
					className="add-content-button"
					onClick={() => setShowUploadModal(true)}
					src='/icons/add.png'
					alt="Add Content"
				/>
			)}
			{showUploadModal && <UploadModal onFileUpload={props.onFileUpload} close={() => {
				setShowUploadModal(false)
			}} />}
			<div style={s.textContainer}>
				<textarea
					className='chat-input-textarea'
					disabled={awaitingResponse}
					style={s.textArea}
					value={input}
					onChange={(e) => {
						onInputChange(e)
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter' && !e.shiftKey) {
							e.preventDefault() 
							trySendMessage()
						}
					}}
					placeholder='Ask me anything...'
					rows={rows}
				/>
				{props.voiceToText.enabled && <FillButton 
					style={s.micIcon} 
					onClick={props.isRecording ? props.voiceToText.stop : props.voiceToText.start} 
					src='/icons/blue-mic.png' 
					alt="Voice to Text" 
				/>}
			</div>
			<FillButton style={{ ...s.sendIcon, ...addedButtonStyle }} onClick={trySendMessage} src='/icons/send.png' alt="Send Message" />
		</div>
	)
}

const s = createStyles({
	container: {
		...gs.spread,
		width: 'calc(100% - 2rem)',
		padding: '1rem',
		paddingBottom: 0
	},
	addIcon: {
		height: '1.5rem',
		width: '1.5rem',
	},
	micIcon: {
		height: '1rem',
		width: '0.75rem',
	},
	sendIcon: {
		height: '1.5rem',
		width: '1.5rem',
	},
	textContainer: {
		...gs.spread,
		borderRadius: '1rem',
		border: `1px solid ${colors.lightishGrey}`,
		padding: '0.5rem 0.8rem',
		margin: '0 1rem',
	},
	textArea: {
		width: '100%',
		flexShrink: 1,
		marginRight: '1rem',
		border: 'none',
		outline: 'none',
		fontSize: '0.9rem',
		cursor: 'pointer',
		resize: 'none',
		textAlign: 'left',
		backgroundColor: 'transparent',
		fontWeight: 300,
	}
})