import { Mode2DataValueType } from "../contexts/messages"
import { DataResponse, DataResponseType } from "../types/data"
import { DirectoryFile } from "../types/files"
import { ChatResponseType, GenerateQuestionResponseType, QuestionType } from "../types/messages"
import { failureMessage, getDirectoryFilesHistory, getFileHistoryType } from "../utils/messages"
import { SessionStorage } from "../utils/sessionStorage"
import { API_URL } from "./api"

export const getMode1Response = async (input: string, schoolBoard: string, chatID: string, fileMode?: 'directory' | 'uploaded' | null, directoryFiles?: DirectoryFile[]): Promise<DataResponseType<ChatResponseType>> => {
	try {
		const formData = new FormData()
		formData.append('user_input', input)
		formData.append('select_board', schoolBoard)
		if (chatID) formData.append('chat_id', chatID)
		let url = API_URL
		if (fileMode === 'uploaded') {
			url += '/chat_upload'
		} else if (fileMode === 'directory') {
			let fileName = ''
			let boardName = ''
			directoryFiles?.forEach((file) => {
				fileName += file.file_name + ','
				boardName += file.board_name + ','
			})
			if (fileName.length > 0) fileName = fileName.slice(0, -1)
			if (boardName.length > 0) boardName = boardName.slice(0, -1)
			formData.append('file_name', fileName)
			formData.append('board_name', boardName)
			url += '/select'
		} else {
			url += '/chatall'
		}

		const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		//TODO corrent form data integration
		if (response.ok) {
			const result = await response.json() as ChatResponseType
			console.log("result", result);
			return DataResponse.success(result)
		} else {
			return DataResponse.warning('Failed to upload file.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to upload file.')
	}
}

export const getUserPromptResponse = async (input: string, chatID: string): Promise<DataResponseType<ChatResponseType>> => {
	try {
		const formData = new FormData()
		formData.append('user_input', input)
		if (chatID) formData.append('chat_id', chatID)
		const url = API_URL + '/chat'
		const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		if (response.ok) {
			const result = await response.json() as ChatResponseType
			return DataResponse.success(result)
		} else {
			return DataResponse.warning('Failed to upload file.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to upload file.')
	}
}

export const generateQuestions = async (chatID: string, schoolBoard: string, questionType: QuestionType, fileMode?: 'directory' | 'uploaded' | null, directoryFiles?: DirectoryFile[]): Promise<DataResponseType<GenerateQuestionResponseType>> => {
	try {
		const formData = new FormData()
		formData.append('chat_id', chatID)
		formData.append('select_board', schoolBoard)
		formData.append('question_type', questionType)
		if (directoryFiles && fileMode === 'directory') {
			let fileName = ''
			let boardName = ''
			directoryFiles?.forEach((file) => {
				fileName += file.file_name + ','
				boardName += file.board_name + ','
			})
			if (fileName.length > 0) fileName = fileName.slice(0, -1)
			if (boardName.length > 0) boardName = boardName.slice(0, -1)
			formData.append('document_name', fileName)
			formData.append('board_name', boardName)
		}
		const url = API_URL + '/generate_questions'
		const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		if (response.ok) {
			const result = await response.json() as GenerateQuestionResponseType
			return DataResponse.success(result)
		} else {
			return DataResponse.warning('Failed to upload file.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to upload file.')
	}
}

export const tryGenerateQuestions = async (
	addMessage: Mode2DataValueType['addMessage'],
	messages: Mode2DataValueType['messages'],
	directoryFiles: DirectoryFile[] | null,
	chatID: string,
	schoolBoard: string,
	setChatID: Mode2DataValueType['setChatID'],
	questionType: QuestionType,
	setQuestions: Mode2DataValueType['setQuestions'],
	addIdToLastMessage: Mode2DataValueType['addIdToLastMessage']
) => {
	let newMessages = addMessage(messages, {
		type: 'generate_questions',
		message: 'Generate Questions'
	})
	const fileMode = getFileHistoryType(messages)
	const directoryFilesHistory = (fileMode === 'directory' && directoryFiles !== null) ? 
		getDirectoryFilesHistory(messages, directoryFiles) : undefined
	try {
		const res = await generateQuestions(chatID, schoolBoard, questionType, fileMode, directoryFilesHistory)
		if (!res.success) throw Error(res.error.message)
		setChatID(res.data.chat_id)
		newMessages = addIdToLastMessage(newMessages, res.data.request_id as number)
		if (res.data.continue_chat) {
			addMessage(newMessages, {
				type: 'bot_question_prompt',
				message: res.data.message,
				message_id: res.data.response_id
			})
			return
		}
		const questions = res.data.questions
		for (const question of questions) {
			if (question.type === 'mcq') {
				const options = [question.answer, ...question.distractors]
				options.sort(() => Math.random() - 0.5)
				question.options = options
			}
		}
		
		const newQuestion = questions.pop()
		setQuestions(questions)
		const type = 'bot_question_' + newQuestion?.type  as 'bot_question_mcq' | 'bot_question_oeq'
		addMessage(newMessages, {
			type,
			question: newQuestion,
			message_id: newQuestion?.id
		})
	} catch (e) {
		console.error(e)
		addMessage(newMessages, failureMessage)
	}
}

export const evaluateQuestion = async (
	userAnswer: string, 
	correctAnswer: string,
	question: string,
	questionId: number,
	questionType: QuestionType,
	chatID: string
): Promise<DataResponseType<ChatResponseType>> => {
	try {
		const formData = new FormData()
		formData.append('correct_answer', correctAnswer)
		formData.append('user_answer', userAnswer)
		formData.append('question', question)
		formData.append('question_type', questionType)
		formData.append('question_id', questionId.toString())
		formData.append('chat_id', chatID)
		const url = API_URL + '/evaluate_questions'
		const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		if (response.ok) {
			const result = await response.json() as ChatResponseType
			return DataResponse.success(result)
		} else {
			return DataResponse.warning('Failed to evaluate question')
		}
	} catch (e) {
		console.error(e)
		return DataResponse.warning('Failed to evaluate question')
	}
}

// export const selectQuestionType = async (chatID: string, type: QuestionType): Promise<DataResponseType<ChatResponseType>> => {
// 	await sleep(1000)
// 	return DataResponse.success({
// 		response: 'Ok, what would you like to be tested on?', 
// 		chat_id: chatID
// 	})
// }
