export const colors = {
	primary: 'rgb(0, 65, 153)',
	primaryLight: '#5598FF5E',
	secondary: '#A8C5EC',
	tertiary: '#DAE7F9',
	error: '#F5A3A3',
	errorDark: '#720000',
	text: '#000000',
	grey: '#808080',
	lightGrey: '#F2F4F5',
	lightishGrey: '#8080801A',
	darkGrey: '#404040',
	background: '#FAFAFA',
	iconBackground: '#EDF0F2',
}
