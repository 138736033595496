import React from 'react'

export const LoadingDots = (props: {
	color?: string
}): JSX.Element => {
	const [dots, setDots] = React.useState('•')
	const color = props.color || '#AAAAAA'
	React.useEffect(() => {
		const interval = setInterval(() => {
			setDots((prevDots) => {
				if (prevDots === '•••') return '•'
				else return prevDots + '•'
			})
		}, 500)

		return () => {
			clearInterval(interval)
		}
	}, [])

	return <p style={{
		width: '2rem', 
		fontSize: '1.5rem', 
		textAlign: 'center',
		color,
	}}>{dots}</p>
}
