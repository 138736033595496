import React, { useEffect, useState } from 'react';
import { LoadingView } from './views/Loading';
import { SelectModeView } from './views/SelectMode';
import { Menu } from './components/menu/Menu';
import { DisplayContext, View } from './contexts/display';
import { gs } from './styles';
import { DirectoryView } from './views/Directory';
import { Mode1DataContext, Mode2DataContext, generateDataValue } from './contexts/messages';
import { Mode1MessageType, Mode2MessageType, Question, QuestionType, initialMode2Messages } from './types/messages';
import { DirectoryFile } from './types/files';
import { ChatView } from './views/ChatView';
import { FilesContext } from './contexts/files';
import { tryGenerateQuestions } from './data/messages';
import { getDefaultSchoolBoard } from './utils/schoolBoard';

const App = () => {
  const [view, setView] = useState<View>('loading');
  const [mobile, setMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const displayValue = {
    mobile, setMobile,
    menuOpen, setMenuOpen,
    view, setView
  }

  const [directoryFiles, setDirectoryFiles] = useState<DirectoryFile[] | null>(null)
  const [uploading, setUploading] = useState(false)
  const filesValue = { directoryFiles, setDirectoryFiles, uploading, setUploading }

  const [mode1Messages, setMode1Messages] = useState<Mode1MessageType[]>([])
	const [mode1Input, setMode1Input] = useState('')
	const [mode1AwaitingResponse, setMode1AwaitingResponse] = useState(false)
	const [mode1ChatID, setMode1ChatID] = useState('')
  const [mode1SchoolBoard, setMode1SchoolBoard] = useState<string>(getDefaultSchoolBoard())
	const mode1DataValue = {
    ...generateDataValue(
      mode1Messages, 
      setMode1Messages, 
      () => {
        setMode1Messages([])
        setMode1ChatID('')
      },
      setMode1SchoolBoard,
    ),
    schoolBoard: mode1SchoolBoard, 
    awaitingResponse: mode1AwaitingResponse, 
    setAwaitingResponse: setMode1AwaitingResponse, 
    input: mode1Input, 
    setInput: setMode1Input, 
    chatID: mode1ChatID, 
    setChatID: setMode1ChatID,
  }
  
  const [mode2Messages, setMode2Messages] = useState<Mode2MessageType[]>(initialMode2Messages)
	const [mode2Input, setMode2Input] = useState('')
	const [mode2AwaitingResponse, setMode2AwaitingResponse] = useState(false)
	const [mode2ChatID, setMode2ChatID] = useState('')
  const [mode2SchoolBoard, setMode2SchoolBoard] = useState<string>(getDefaultSchoolBoard())
	const [questionType, setQuestionType] = useState<QuestionType>('both')
  const [questions, setQuestions] = useState<Question[]>([])
	const mode2DataValue = {
    ...generateDataValue(
      mode2Messages, 
      setMode2Messages, 
      () => {
        setMode2Messages(initialMode2Messages)
        setMode2ChatID('')
        setQuestionType('both')
      },
      setMode2SchoolBoard,
    ),
    schoolBoard: mode2SchoolBoard, 
    awaitingResponse: mode2AwaitingResponse, 
    setAwaitingResponse: setMode2AwaitingResponse, 
    input: mode2Input, 
    setInput: setMode2Input, 
    chatID: mode2ChatID, 
    setChatID: setMode2ChatID,
    setQuestionType,
    questionType,
    questions,
    setQuestions,
  }


  useEffect(() => {
    const updateMobile = () => {
      setMobile(window.innerWidth < 500);
    };
    updateMobile();
    window.addEventListener('resize', updateMobile);
    return () => window.removeEventListener('resize', updateMobile);
  })

  const onDirectoryFileSelect = async (files: DirectoryFile[]) => {
    if (view === 'mode1_directory') {
      mode1DataValue.addMessages(mode1Messages, files.map((file) => ({
        type: 'directory_file',
        filename: file.file_name,
        board_name: file.board_name,
      })));
      setView('mode1');
    } else if (view === 'mode2_directory') {
      const newMessages = mode2DataValue.addMessages(mode2Messages, files.map((file) => ({
        type: 'directory_file',
        filename: file.file_name,
        board_name: file.board_name,
      })));
      setView('mode2');
      setMode2AwaitingResponse(true);
      await tryGenerateQuestions(
        mode2DataValue.addMessage, 
        newMessages,
        directoryFiles, 
        mode2ChatID,
        mode2DataValue.schoolBoard,
        mode2DataValue.setChatID,
        mode2DataValue.questionType, 
        mode2DataValue.setQuestions,
        mode2DataValue.addIdToLastMessage
      )
      setMode2AwaitingResponse(false);
    }
  }

  return (
    <DisplayContext.Provider value={displayValue}>
      <Mode1DataContext.Provider value={mode1DataValue}>
        <Mode2DataContext.Provider value={mode2DataValue}>
          <FilesContext.Provider value={filesValue}>
            <div style={{ ...gs.centered, height: '100%', width: '100%', maxWidth: '100%' }}>
              {menuOpen && <Menu view={view} setView={setView} />}
              <div style={{ width: menuOpen ? 'calc(100% - 18rem)' : '100%', height: '100%' }}>
                {view === 'loading' && <LoadingView next={() => setView('select')}/>}
                {view === 'select' && <SelectModeView />}
                {view === 'mode1' && <ChatView mode={1} />}
                {view === 'mode2' && <ChatView mode={2} />}
                {view.includes('directory') && <DirectoryView onSelect={onDirectoryFileSelect} />}
              </div>
            </div>
          </FilesContext.Provider>
        </Mode2DataContext.Provider>
      </Mode1DataContext.Provider>
    </DisplayContext.Provider>
  );
}

export default App;
