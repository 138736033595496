import { useRef } from "react"
import { HiddenFileUpload } from "../chat/HiddenFileUpload"
import { createStyles, gs } from "../../styles"
import { Modal } from "./Modal"
import { useDisplay } from "../../hooks"
import { FillButton } from "../FillButton"

export const UploadModal = (props: {
	onFileUpload: (files: FileList) => void,
	close: () => void
}) => {
	const fileUploadRef = useRef<HTMLInputElement>(null)
	const { view, setView } = useDisplay()
	const onFileChange = (file: FileList) => {
		props.onFileUpload(file)
		props.close()
	}
	return (
		<Modal close={props.close} style={s.container}>
			<HiddenFileUpload inputRef={fileUploadRef} onFileChange={onFileChange} />
			<div style={gs.spread}>
				<div></div>
				<FillButton onClick={props.close} style={s.closeIcon} src={'/icons/close.png'} alt="Close" />
			</div>
			<button style={s.modalButton} className="directory-select-button" onClick={(e) => {
				e.stopPropagation()
				if (view === 'mode1') setView("mode1_directory")
				else if (view === 'mode2') setView("mode2_directory")
				else console.error("Invalid view")
			}}>
				<img src={'/icons/book.png'} alt="Select File" style={s.bookIcon} />
				<p>Select Files from Directory</p>
			</button>
			<button style={s.modalButton} className="upload-files-button" onClick={(e) => {
				e.stopPropagation()
				fileUploadRef.current?.click()
			}}>
				<img src={'/icons/upload-cloud.png'} alt="Upload File" style={s.cloudIcon} />
				<p>Upload File</p>
			</button>
		</Modal>
	)
}

const s = createStyles({
	container: {
		width: 'min(15rem, 100%)',
		padding: '0.5rem 0.5rem 1rem 1rem',
	},
	modalButton: {
		...gs.left,
		width: '100%',
		padding: '0.5rem',
	},
	closeIcon: {
		height: '1.2rem',
		width: '1.2rem',
	},
	bookIcon: {
		height: '1.5rem',
		width: '1.5rem',
		marginRight: '0.5rem'
	},
	cloudIcon: {
		height: '1.2rem',
		width: '1.5rem',
		marginRight: '0.5rem'
	},
})
