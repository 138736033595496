import { DirectoryFile } from "../types/files"
import { Mode1MessageType, Mode2MessageType, Question } from "../types/messages"

export const userMessageType = (messages: Mode2MessageType[]): Mode2MessageType['type'] | null=> {
	for (let i = messages.length - 1; i >= 0; i--) {
		const message = messages[i]
		if (message.type.includes('bot')) {
			if (message.type === 'bot_prompt') return 'user_prompt'
			if (message.type === 'bot_question_prompt') return 'user_prompt'
			if (message.type === 'bot_question_mcq') return 'answer'
			if (message.type === 'bot_question_oeq') return 'answer'
			if (message.type === 'bot_select') return 'user_select'
			else return 'user_prompt'
		}
	}
	return null
}

export const getFileHistoryType = (messages: (Mode2MessageType | Mode1MessageType)[]): 'directory' | 'uploaded' | null => {
	for (let i = messages.length - 1; i >= 0; i--) {
		const message = messages[i]
		if (message.type === 'directory_file' || message.type === 'uploaded_file') return message.type.split('_')[0] as 'directory' | 'uploaded'
	}
	return null
}

export const getDirectoryFilesHistory = (messages: (Mode1MessageType | Mode2MessageType)[], directoryFiles: DirectoryFile[]): DirectoryFile[] => {
	const files: DirectoryFile[] = []
	for (let i = messages.length - 1; i >= 0; i--) {
		const message = messages[i]
		if (message.type === 'directory_file') {
			const file = directoryFiles.find(file => file.file_name === message.filename)
			if (file) files.push(file)
		}
	}
	return files
}

export const getLastQuestion = (message: Mode2MessageType[]): Question | null | undefined => {
	for (let i = message.length - 1; i >= 0; i--) {
		const msg = message[i]
		if (msg.type === 'bot_question_mcq' || msg.type === 'bot_question_oeq') return msg.question
	}
	return null
}

export const failureMessage = { message: 'Something went wrong', type: 'bot_error' } as const
