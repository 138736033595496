import { createStyles } from "../../styles"
import { DirectoryFile } from "../../types/files"
import { Modal } from "./Modal"

export const FilePreviewModal = (props: {
	close: () => void
	file: DirectoryFile
}) => {
	return (
		<Modal close={props.close} style={s.modal}>
			<p style={s.text}>{props.file.file_name}</p>
			<iframe
				title={props.file.file_name + " preview"}
				src={props.file.s3_url}
				style={{ width: '100%', height: '100%' }}
			/>
		</Modal>
	)
}

const s = createStyles({
	modal: {
		borderRadius: '0.8rem',
		padding: '1rem 2rem',
		width: 'min(24rem, 100%)',
		height: 'min(30rem, 100%)',
	},
	text: {
		marginBottom: '1rem',
		wordBreak: 'break-word',
	},
})