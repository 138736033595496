import { createContext } from 'react'
import { DirectoryFile } from '../types/files'

interface FilesValueType {
	directoryFiles: DirectoryFile[] | null,
	setDirectoryFiles: (newDirectoryFiles: DirectoryFile[]) => void,
	uploading: boolean,
	setUploading: (uploading: boolean) => void
}

export const FilesContext = createContext<FilesValueType>({
	directoryFiles: null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setDirectoryFiles: (newDirectoryFiles: DirectoryFile[]) => (void 0),
	uploading: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setUploading: (uploading: boolean) => (void 0)
})
