// SpeechRecognition setup
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

const recognitionConstructor = window.SpeechRecognition || window.webkitSpeechRecognition

export class VoiceToText {
	private recognition: SpeechRecognition | null
	public enabled = false
	constructor(
		private setIsRecording: (isRecording: boolean) => void,
		private setInput: (input: string) => void,
		private existingInput: string = ''
	) {
		this.recognition = SpeechRecognition ? new SpeechRecognition() : null
		if (!this.recognition) {
			console.error('Speech Recognition API is not supported in this browser.')
		} else {
			this.enabled = true
		}
		this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
	}

	start(): void {
		if (recognitionConstructor) {
			const recognition = new recognitionConstructor()
			recognition.start()
			this.setIsRecording(true)
			const setIsRecording = this.setIsRecording
			
			recognition.onresult = (event: any) => {
				const transcript = Array.from(event.results)
					.map((result: any) => result[0])
					.map((result) => result.transcript)
					.join('')
				this.setResult(transcript)
				recognition.stop()
				setIsRecording(false)
			}

			// recognition.onerror = (event: Event) => {
			// 	console.error('Speech Recognition Error: ', (event as any).error)
			// 	setIsListening(false)
			// }
		}
	}

	updateExistingInput(text: string): void {
		this.existingInput = text
	}

	stop(): void {
		this.recognition?.stop()
		this.setIsRecording(false)
	}

	setResult(text: string): void {
		this.setInput(this.existingInput + text)
	}
}
