export interface ChatResponseType {
	response: string
	chat_id: string
	response_id?: number
	request_id?: number
}

export interface MCQuestion {
	id: number
	type: 'mcq'
	question: string
	answer: string
	distractors: string[]
	options: string[]
}

export interface OEQuestion {
	id: number
	type: 'oeq'
	question: string
	answer: string
}

export type Question = MCQuestion | OEQuestion

export type GenerateQuestionResponseType = {
	chat_id: string,
	request_id: number,
	questions: Question[],

	message?: undefined
	continue_chat?: undefined
} | {
	chat_id: string
	request_id: number
	message: string
	continue_chat: true
	response_id: number

	questions?: undefined
}

export type QuestionType = 'mcq' | 'oeq' | 'both'

export interface MessageType {
	message?: string,
	filename?: string
	board_name?: string
	feedback?: boolean
	message_id?: number
}

export interface Mode1MessageType extends MessageType {
	type: 'user' | 'bot_error' | 'bot' | 'uploaded_file' | 'failed_file' | 'directory_file',
}


export interface Mode2MessageType extends MessageType {
	type: 'bot' | 'bot_error' | 'bot_question_prompt' | 'bot_prompt' | 'bot_question_mcq' | 'bot_question_oeq' | 'bot_select' | 'user_select' | 'user_prompt' | 'answer' | 'uploaded_file' | 'failed_file' | 'directory_file' | 'generate_questions',
	question?: Question
}

export const initialMode2Messages: Mode2MessageType[] = [{
  type: 'bot',
  message: "Hello, I'm PrincipalBOT! 👋 I'm your personal study assistant. Please select an activity mode."
}, {
  type: 'bot_select',
}, {
	type: 'bot_question_prompt',
	message: 'Tell me a topic that you want to generate questions for or upload a document or select a document.'	
}]
