import { RefObject } from "react"

export const HiddenFileUpload = (props: {
	inputRef: RefObject<HTMLInputElement>,
	onFileChange: (files: FileList) => void
}) => {
	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		if (files) {
			props.onFileChange(files)
		}
	}
	return (
		<input
			type="file"
			multiple
			style={{ display: 'none' }}
			ref={props.inputRef}
			accept=".pdf, .docx, .txt"
			onChange={handleFileChange}
			onClick={(e) => e.stopPropagation()}
		/>
	)
}
