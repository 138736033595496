import { evaluateQuestion, tryGenerateQuestions } from "../../data/messages"
import { useFiles, useMode2Data } from "../../hooks"
import { createStyles, gs } from "../../styles"
import { MCQuestion, Mode2MessageType, QuestionType } from "../../types/messages"
import { failureMessage } from "../../utils/messages"

export const QuestionTypeSelect = () => {
	const { addMessages, messages, questionType, setQuestionType } = useMode2Data()
	const selectQuestionType = (type: QuestionType) => {
		if (type === questionType) return
		setQuestionType(type)
		const message = type === 'mcq' ? 'Multiple Choice' : 'Short Answer'
		addMessages(messages, [{
			type: 'user_select',
			message
		}, {
			type: 'bot',
			message: 'Ok, what would you like to be tested on?'
		}])
	}

	const selectedStyle = {
		...s.selectButton,
		backgroundColor: '#A8C5EC'
	}

	return (
		<div style={s.selectContainer}>
			<button style={questionType === 'mcq' ? selectedStyle : s.selectButton} onClick={() => {
				selectQuestionType('mcq')
			}}>
				<p>Multiple Choice</p>
				<img src={'icons/multiple-choice.png'} style={s.selectButtonIcon} alt='Multiple Choice' />
			</button>
			<button style={questionType === 'oeq' ? selectedStyle : s.selectButton} onClick={() => {
				selectQuestionType('oeq')
			}}>
				<p>Short Answer</p>
				<img src={'icons/open-ended.png'} style={s.selectButtonIcon} alt='Short Answer' />
			</button>
			<button style={questionType === 'both' ? selectedStyle : s.selectButton} onClick={() => {
				selectQuestionType('both')
			}}>
				<p>Both</p>
				<img src={'icons/multiple-choice.png'} style={s.selectButtonIcon} alt='Multiple Choice' />
				<img src={'icons/open-ended.png'} style={s.selectButtonIcon} alt='Short Answer' />
			</button>
		</div>
	)
}

export const BotGeneratePrompt = (props: {
	message: Mode2MessageType,
}) => {
	const { directoryFiles } = useFiles()
	const { 
		chatID, 
		setChatID, 
		addMessage, 
		messages, 
		questionType, 
		setQuestions,
		setAwaitingResponse,
		schoolBoard,
		addIdToLastMessage,
	} = useMode2Data()
	const onClickGenerateQuestions = async () => {
		setAwaitingResponse(true)
		await tryGenerateQuestions(
			addMessage, 
			messages, 
			directoryFiles, 
			chatID, 
			schoolBoard,
			setChatID,
			questionType, 
			setQuestions,
			addIdToLastMessage
		)
		setAwaitingResponse(false)
	}
	return (
		<div style={s.selectContainer}>
			<p style={s.bubbleText}>{props.message.message}</p>
			<button style={s.selectButton} onClick={onClickGenerateQuestions}>
				<p>Generate Questions</p>
				{/* <img src={'icons/multiple-choice.png'} style={s.selectButtonIcon} alt='Multiple Choice' /> */}
			</button>
		</div>
	)
}

export const BotMCQuestion = (props: {
	message: Mode2MessageType,
}) => {
	const { chatID, addMessage, messages, questions, setQuestions, addMessages, addIdToLastMessage } = useMode2Data()
	const question = props.message.question as MCQuestion
	const tryEvaluateQuestion = async (answer: string) => {
		let newMessages = addMessage(messages, {
			type: 'answer',
			message: answer
		})
		try {
			const res = await evaluateQuestion(
				answer,
				question.answer,
				question.question,
				question.id,
				question.type,
				chatID
			)
			if (!res.success) throw Error(res.error.message)
			newMessages = addIdToLastMessage(newMessages, res.data.request_id as number)
			const message = res.data.response
			if (questions.length === 0) {
				addMessage(newMessages, {
					type: 'bot',
					message,
					message_id: res.data.response_id,
				})
			} else {
				const newQuestion = questions.pop()
				setQuestions([...questions])
				const type = 'bot_question_' + newQuestion?.type  as 'bot_question_mcq' | 'bot_question_oeq'
				addMessages(newMessages, [{
					type: 'bot',
					message,
					message_id: res.data.response_id,
				}, {
					type,
					question: newQuestion,
					message_id: newQuestion?.id,
				}])
			}
		} catch (e) {
			console.error(e)
			addMessage(newMessages, failureMessage)
		}
	}
	return (
		<div style={s.selectContainer}>
			<p style={s.bubbleText}>{question.question}</p>
			{question.options.map((answer, i) => (
				<button key={i} style={s.selectButton} onClick={() => tryEvaluateQuestion(answer)}>
					<p>{answer}</p>
				</button>
			))}
		</div>
	)
}

const s = createStyles({
	selectContainer: {
		...gs.leftCol,
	},
	selectButton: {
		display: 'flex',
		backgroundColor: 'white',
		margin: '0.3rem',
		borderRadius: '0.8rem',
		padding: '0.5rem',
	},
	selectButtonIcon: {
		marginLeft: '0.3rem',
		height: '1rem',
		width: '1rem',
	},
	bubbleText: {
		fontSize: '0.9rem',
		textAlign: 'left',
		wordWrap: 'break-word',
		maxWidth: '100%',
		fontWeight: 300,
		marginBottom: '0.5rem'
	},
})
