import { CSSProperties } from "react"
import { gs } from "../styles"

export const FillButton = (props: {
	src: string,
	style?: CSSProperties
	alt?: string
	onClick: () => void
	className?: string
	id?: string
}) => {
	return (
		<button 
			style={props.style ?? gs.buttonIcon} 
			onClick={props.onClick}
			className={props.className}
			id={props.id}
		>
			<img src={props.src} alt={props.alt} style={gs.fillImage} />
		</button>
	)
}
