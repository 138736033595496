import { createStyles, gs } from "../../styles"

export const Modal = (props: {
	close: () => void,
	children: JSX.Element | JSX.Element[],
	style?: React.CSSProperties
}) => {
	return (
		<div style={s.background} onClick={props.close}>
			<div style={{ ...s.modal, ...props.style }}>
				{props.children}
			</div>
		</div>
	)
}

const s = createStyles({
	background: {
		height: '100%',
		width: '100%',
		backgroundColor: '#000000A6',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 1
	},
	modal: {
		...gs.column,
		backgroundColor: 'white',
		borderRadius: '0.8rem',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '1rem 2rem',
		width: 'min(13rem, 100%)',
	},
})