import { createStyles } from "../../styles"
import { Modal } from "./Modal"

export const WarningModal = (props: {
	close: () => void
}) => {
	return (
		<Modal close={props.close} style={s.modal}>
			<p style={s.text}>Please do not share any personal information with the chatbot</p>
			<button style={s.button} onClick={props.close}>Confirm</button>
		</Modal>
	)
}

const s = createStyles({
	modal: {
		borderRadius: '0.8rem',
		padding: '1rem 2rem',
		width: 'min(13rem, 100%)',
	},
	text: {
		marginBottom: '1rem'
	},
	button: {
		border: '1px solid lightgrey',
		padding: '0.1rem 0.2rem',
		borderRadius: '0.3rem',
	}
})