import { BlueRoundedButton } from "../BlueRoudedButton"

export const BackButton = (props: {
	back: () => void
}) => {
	return (<BlueRoundedButton
		className="back-button"
		onClick={props.back}
		icon={'/icons/arrow-back.png'}
		title={'Back'}
	/>)
}
