import { createContext } from 'react'
import { Mode1MessageType, Mode2MessageType, Question, QuestionType } from '../types/messages'
import { setDefaultSchoolBoard } from '../utils/schoolBoard'

interface DataValueType<MessageType> {
	messages: MessageType[],
	addMessage: (messages: MessageType[], message: MessageType) => MessageType[]
	addMessages: (messages: MessageType[], addMessages: MessageType[]) => MessageType[]
	awaitingResponse: boolean
	setAwaitingResponse: (awaitingResponse: boolean) => void
	input: string,
	setInput: (input: string) => void
	chatID: string
	setChatID: (chatID: string) => void
	resetMessages: () => void
	schoolBoard: string
	setSchoolBoard: (schoolBoard: string) => void
	addIdToLastMessage: (messages: MessageType[], id: number) => MessageType[]
	updateMessageFeedback: (messages: MessageType[], id: number, feedback: boolean) => MessageType[]
}

export type Mode1DataValueType = DataValueType<Mode1MessageType>
export interface Mode2DataValueType extends DataValueType<Mode2MessageType> {
	questionType: QuestionType
	setQuestionType: (questionType: QuestionType) => void
	questions: Question[],
	setQuestions: (questions: Question[]) => void
}

const createDefaultDataValue = <MessageType>(): DataValueType<MessageType> => ({
	messages: [],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	addMessage: (messages: MessageType[], message: MessageType) => ([]),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	addMessages: (messages: MessageType[], addMessages: MessageType[]) => ([]),
	awaitingResponse: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setAwaitingResponse: (awaitingResponse: boolean) => (void 0),
	input: '',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setInput: (input: string) => (void 0),
	chatID: '',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setChatID: (chatID: string) => (void 0),
	resetMessages: () => (void 0),
	schoolBoard: 'wcdsb',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setSchoolBoard: (schoolBoard: string) => (void 0),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	addIdToLastMessage: (messages: MessageType[], id: number) => ([]),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	updateMessageFeedback: (messages: MessageType[], id: number, feedback: boolean) => ([]),
})

export const Mode1DataContext = createContext<Mode1DataValueType>(createDefaultDataValue<Mode1MessageType>())
export const Mode2DataContext = createContext<Mode2DataValueType>({
	...createDefaultDataValue<Mode2MessageType>(),
	setQuestionType: (questionType: QuestionType) => (void 0),
	questionType: 'both',
	questions: [],
	setQuestions: (questions: Question[]) => (void 0)
})

export const generateDataValue = <MessageType>(
	messages: MessageType[],
	setMessages: (newMessages: MessageType[]) => void,
	resetMessages: () => void,
	setSchoolBoard: (schoolBoard: string) => void
): {
	messages: MessageType[],
	addMessage: (messages: MessageType[], message: MessageType) => MessageType[]
	addMessages: (messages: MessageType[], addMessages: MessageType[]) => MessageType[]
	resetMessages: () => void
	setSchoolBoard: (schoolBoard: string) => void
	addIdToLastMessage: (messages: MessageType[], id: number) => MessageType[],
	updateMessageFeedback: (messages: MessageType[], id: number, feedback: boolean) => MessageType[]
} => {
	return {
		messages,
		addMessage: (messages, message) => {
			const newMessages = [...messages]
			newMessages.push({ ...message })
			setMessages(newMessages)
			return newMessages
		},
		addMessages: (messages, addMessages) => {
			const newMessages = [...messages]
			newMessages.push(...addMessages)
			setMessages(newMessages)
			return newMessages
		},
		resetMessages,
		setSchoolBoard: (schoolBoard: string) => {
			setSchoolBoard(schoolBoard)
			setDefaultSchoolBoard(schoolBoard)
		},
		addIdToLastMessage: (messages, id: number) => {
			const newMessages = [...messages] as (Mode1MessageType | Mode2MessageType)[]
			newMessages[newMessages.length - 1].message_id = id
			setMessages(newMessages as MessageType[])
			return newMessages as MessageType[]
		},
		updateMessageFeedback: (messages, id, feedback) => {
			const newMessages = [...messages] as (Mode1MessageType | Mode2MessageType)[]
			const message = newMessages.find(message => message.message_id === id)
			if (message) message.feedback = feedback
			setMessages(newMessages as MessageType[])
			return newMessages as MessageType[]
		}
	}
}
