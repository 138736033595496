import { useContext } from "react"
import { DisplayContext } from "../contexts/display"
import { Mode1DataContext, Mode2DataContext } from "../contexts/messages"
import { FilesContext } from "../contexts/files"

export const useDisplay = () => useContext(DisplayContext)
export const useMode1Data = () => useContext(Mode1DataContext)
export const useMode2Data = () => useContext(Mode2DataContext)
export const useFiles = () => useContext(FilesContext)

export { useData } from './data'

