import { sendFeedback } from '../../data/feedback'
import { useMode1Data, useMode2Data } from '../../hooks'
import { createStyles } from '../../styles'
import { Mode1MessageType, Mode2MessageType } from '../../types/messages'

export const MessageFeedback = ({ mode, message }: {
	mode: 1 | 2,
	message: Mode1MessageType | Mode2MessageType,
}): JSX.Element => {
	const feedbackUpSelected = message.feedback === true
	const feedbackDownSelected = message.feedback === false
	const mode1Data = useMode1Data()
	const mode2Data = useMode2Data()
	const { chatID } = mode === 1 ? mode1Data : mode2Data
	if (!message.message_id) return <></>
	const feedbackUpStyle = feedbackUpSelected ? {
		color: 'rgb(17, 173, 71)',
		fontVariationSettings: "'FILL' 1",
	} : {}
	const feedbackDownStyle = feedbackDownSelected ? {
		color: 'rgb(205, 48, 48)',
		fontVariationSettings: "'FILL' 1",
	} : {}

	const trySendFeedback = async (approve: boolean): Promise<void> => {
		if (!message.message_id) return
		const res = await sendFeedback(message.message_id, approve, chatID)
		if (!res.success) {
			alert('Failed to send feedback')
		} else {
			if (mode === 1) {
				mode1Data.updateMessageFeedback(mode1Data.messages, message.message_id, approve)
			} else {
				mode2Data.updateMessageFeedback(mode2Data.messages, message.message_id, approve)
			}
			alert('Feedback sent!')
		}
	}

	return (
		<div style={{ ...s.container }}>
			<span 
				className={`material-symbols-outlined hover-green-fill`}
				style={{ ...s.thumb, ...feedbackUpStyle }}
				onClick={() => trySendFeedback(true)}>thumb_up</span>
			<span 
				className={`material-symbols-outlined hover-red-fill`}
				style={{ ...s.thumb, ...feedbackDownStyle }} 
				onClick={() => trySendFeedback(false)}>thumb_down</span>
		</div>
	)
}


// border-radius: 0.5rem;
// 	display: flex;
// 	background-color: white;
// 	height: 1.2rem;
// 	padding: 0.1rem;

const s = createStyles({
	container: {
		borderRadius: '0.5rem',
		display: 'flex',
		padding: '0.1rem',
		alignItems: 'flex-end',
		justifyContent: 'center',
		height: 'calc(100% + 0.4rem)',
		marginLeft: '-2.5rem',
	},
	thumb: {
		cursor: 'pointer',
	}
})