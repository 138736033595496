import { useDisplay } from "../hooks"
import { colors, createStyles } from "../styles"
import { FillButton } from "./FillButton"

const schoolBoards = [
    "wcdsb",
    "tdsb",
    "wrdsb",
    "york",
	"other",
]

export const Header = (props: {
	reset?: () => void,
	setSchoolBoard?: (schoolBoard: string) => void,
}) => {
	const { setMenuOpen, menuOpen } = useDisplay()
	return (
		<header style={s.header}>
			<div style={{
				...s.headerSide,
				justifyContent: 'flex-start',
			}}>
				<FillButton 
					style={s.icon} 
					onClick={() => setMenuOpen(!menuOpen)} 
					src='/icons/menu.png' 
					alt="Open Menu"
					id="menu-toggle-button"
				/>
			</div>
			<h2 style={s.title}>PrincipalBOT</h2>
			<div style={{
				...s.headerSide,
				justifyContent: 'flex-end',
			}}>
				{props.setSchoolBoard && <select 
					style={s.schoolBoardSelect}
					onChange={(e) => props.setSchoolBoard?.(e.target.value)}
				>
					{schoolBoards.map((board, i) => (
						<option key={i}>{board}</option>
					))}
				</select>}
				{props.reset && <FillButton style={s.refreshIcon} onClick={props.reset} src='/icons/refresh.png' alt="Menu" />}
			</div>
		</header>
	)
}

const s = createStyles({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: 'calc(100% - 2rem)',
		height: '4rem',
		padding: '0 1rem',
	},
	title: {
		fontSize: '1.5rem',
		fontWeight: 'normal',
		color: colors.primary,
	},
	icon: {
		width: '2rem',
		height: '2rem',
	},
	refreshIcon: {
		width: '1.2rem',
		height: '1.2rem',
	},
	headerSide: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		padding: '0 1rem',
	},
	schoolBoardSelect: {
		marginRight: '1rem',
		backgroundColor: 'white',
		border: '1px solid #2222221A',
		padding: '0.5rem',
		borderRadius: '0.3rem',
	},
})
