import { gs } from "../../styles"
import { FillImage } from "../FillImage"

export const SettingsMenu = () => {
	return (<>
		<div style={{ ...gs.left, margin: '0.5rem' }}>
			<FillImage style={{ height: '1.3rem', width: '1.3rem', marginRight: '0.5rem' }}src='/icons/menu-settings.png' alt='Settings' />
			<h5 style={gs.menuTitleText}>Settings</h5>
		</div>
	</>)
}

