export type LetteredList<T> = {
	letter: string
	items: T[]
}[]

export const getLetteredList = <T, T2 extends string>(items: (T & { [k in T2]: string })[], key: T2): LetteredList<T> => {
	const letteredList: LetteredList<T> = []
	items.forEach((item, i) => {
		const letter = item[key][0]
		const letteredListItem = letteredList.find((item) => item.letter === letter)
		if (letteredListItem) {
			letteredListItem.items.push(item)
		} else {
			letteredList.push({
				letter,
				items:  [item]
			})
		}
	})
	letteredList.sort((a, b) => a.letter.localeCompare(b.letter))
	return letteredList
}