import { useEffect, useMemo, useState } from "react"
import { Header } from "../components/Header"
import { VoiceToText } from "../utils/audio"
import { ChatInput } from "../components/chat/ChatInput"
import { WarningModal } from "../components/modal/WarningModal"
import { SessionStorage } from "../utils/sessionStorage"
import { colors, createStyles, gs } from "../styles"
import { useFiles, useMode1Data, useMode2Data } from "../hooks"
import { uploadFiles } from "../data/files"
import { Mode1Placeholder } from "../components/chat/Mode1Placeholder"
import { Messages } from "../components/chat/Messages"
import { tryGenerateQuestions } from "../data/messages"
import { LoadingDots } from "../components/chat/LoadingDots"

export const ChatView = (props: {
	mode: 1 | 2
}) => {
	const mode1 = props.mode === 1
	const mode1Data = useMode1Data()
	const mode2Data = useMode2Data()
	const { 
		input,
		setInput,
		chatID,
		setChatID,
		resetMessages,
		schoolBoard,
		setSchoolBoard,
	} = mode1 ? mode1Data : mode2Data
	const { directoryFiles, uploading, setUploading } = useFiles()
	const [warningOpen, setWarningOpen] = useState(false)
	const [isRecording, setIsRecording] = useState(false)
	const voiceToText = useMemo(() => new VoiceToText(setIsRecording, (text) => {
		setInput(input + text)
	}), [setIsRecording, setInput])

	useEffect(() => {
		if (!voiceToText.enabled) return
		voiceToText.updateExistingInput(input)
	}, [input])
	
	const placeholderEnabled = mode1Data.messages.length === 0
	const onFileUpload = async (fileList: FileList) => {
		const files = Array.from(fileList);
		setUploading(true)
		const uploadResult = await uploadFiles(chatID, files, props.mode)
		if (uploadResult.success) setChatID(uploadResult.data)
		let newMessages: {
			filename: string;
			type: "uploaded_file" | "failed_file";
		}[] = []
		files.forEach(file => {
			const newMessage = { 
				filename: file.name, 
				type: uploadResult.success ? 'uploaded_file' : 'failed_file' 
			} as const
			newMessages.push(newMessage)
		})
		setUploading(false)
		if (mode1) mode1Data.addMessages(mode1Data.messages, newMessages)
		else {
			const newFileMessages = mode2Data.addMessages(mode2Data.messages, newMessages)
			if (!uploadResult.success) return
			mode2Data.setAwaitingResponse(true)
			await tryGenerateQuestions(
				mode2Data.addMessage, 
				newFileMessages,
				directoryFiles, 
				uploadResult.data, 
				schoolBoard,
				mode2Data.setChatID,
				mode2Data.questionType, 
				mode2Data.setQuestions,
				mode2Data.addIdToLastMessage
			)
			mode2Data.setAwaitingResponse(false)
		}
	}
	useEffect(() => {
		if (SessionStorage.get('personal-info-acknowledged') === 'true') return
		setWarningOpen(true)		
	}, [])

	return (
		<>
			<div style={s.container}>
				<Header reset={resetMessages} setSchoolBoard={setSchoolBoard}/>
				<div style={s.content}>
					{placeholderEnabled && mode1 && (
						<Mode1Placeholder voiceToText={voiceToText} isRecording={isRecording} onFileUpload={onFileUpload} />
					)}
					{!placeholderEnabled && mode1 && (
						<Messages mode={1} messages={mode1Data.messages} />
					)}
					{!mode1 && <Messages mode={2} messages={mode2Data.messages} />}
					<ChatInput
						mode={props.mode}
						voiceToText={voiceToText} 
						isRecording={isRecording} 
						onFileUpload={onFileUpload} 
					/>
					{uploading && (
						<div style={{
							...gs.centered,
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',	
						}}>
							<div style={{
								...gs.column,
								backgroundColor: 'white',
								padding: '1rem',
								borderRadius: '0.5rem',
								width: 'fit-content',
							}}>
								<p>Uploading</p>
								<LoadingDots color='black' />
							</div>
						</div>
					)}
				</div>
				<p style={s.infoWarning}>Please do not enter any personal information.</p>
			</div>
			{warningOpen && <WarningModal close={() => {
				SessionStorage.set('personal-info-acknowledged', 'true')
				setWarningOpen(false)
			}} />}
		</>
	)
}


const s = createStyles({
	container: {
		...gs.column,
		width: '100%',
		height: '100%',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: colors.background,
		width: '100%',
		height: '100%',
		borderTop: `1px solid ${colors.lightGrey}`,
		overflowY: 'scroll',
	},
	infoWarning: {
		margin: '0.3rem 0 0.5rem',
		color: '#303437A6',
		fontSize: '0.7rem',
		fontWeight: 300,
	}
})
