export class SessionStorage {
	static set(name: string, value: string): void {
		window.sessionStorage.setItem(name, value)
	}
	static delete(name: string): void {
		window.sessionStorage.removeItem(name)
	}
	static get(name: string): string | null {
		return window.sessionStorage.getItem(name)
	}
}