import { CSSProperties, useState } from "react"
import { View } from "../../contexts/display"
import { useDisplay } from "../../hooks"
import { colors, createStyles, gs } from "../../styles"
import { BlueRoundedButton } from "../BlueRoudedButton"
import { BackButton } from "./BackButton"
import { SettingsMenu } from "./Settings"
import { AcknowledgementMenu } from "./Acknowledgement"
import { ContactMenu } from "./ContactMenu"
import { FillImage } from "../FillImage"

const menuViews = ['settings', 'acknowledgement', 'contact'] as const
type MenuView = typeof menuViews[number]

type MenuItemType = {
	icon: string,
	title: string,
}

interface MenuItemProps extends MenuItemType {
	bold?: boolean
	onClick: () => void
	className?: string
}

const MenuItem = (props: MenuItemProps) => {
	const addedSyles = {
		fontWeight: props.bold ? 400 : 300
	}
	return (
		<BlueRoundedButton {...props} textStyle={addedSyles}/>
	)
}

const topMenuItems: (MenuItemType & {
	view: View	
})[] = [
	{ icon: '/icons/menu-message.png', title: 'Live AI Assistant', view: 'mode1' },
	{ icon: '/icons/menu-book.png', title: 'Practice and Training', view: 'mode2' },
	{ icon: '/icons/menu-folder.png', title: 'Directory', view: 'view_directory' },
]
const bottomMenuItems: (MenuItemType & { 
	menuView: MenuView
})[] = [
	{ icon: '/icons/menu-settings.png', title: 'Settings', menuView: 'settings' },
	{ icon: '/icons/menu-acknowledgement.png', title: 'Acknowledgement', menuView: 'acknowledgement' },
	{ icon: '/icons/menu-account.png', title: 'Contact Us', menuView: 'contact' },
]

export const Menu = (props: {
	view: View,
	setView: (newView: View) => void
}) => {
	const [menuView, setMenuView] = useState<MenuView | undefined>()
	const { mobile, setMenuOpen } = useDisplay()
	const containerStyles: CSSProperties = mobile ? {
		...s.container,
		position: 'fixed',
		top: 0,
		width: 'calc(100% - 2rem)',
	} : {
		...s.container,
		width: '16rem',
		minWidth: '16rem',
	}
	return (<>
		<div style={containerStyles} id="menu">
			{!menuView ? (<>
				<div style={gs.column}>
					<div style={gs.centered}>
						<FillImage src='/images/logo.png' alt='Logo' style={{ width: '3rem', height: '2rem', marginRight: '0.5rem' }} />
						<h3 style={s.title}>PrincipalBot</h3>
					</div>
					<div style={s.topMenu}>
						{topMenuItems.map((item, i) => (
							<MenuItem 
								{...item}
								key={i}
								className="page-nav-menu-item"
								onClick={() => {
									if (mobile) setMenuOpen(false)
									props.setView(item.view)
								}}
								bold
							/>
						))}
					</div>
				</div>
				<div style={s.bottomMenu}>
					{bottomMenuItems.map((item, i) => (
						<MenuItem 
							{...item} 
							key={i}
							className="menu-nav-menu-item"
							onClick={() => {
								setMenuView(item.menuView)
							}}
						/>
					))}
				</div>
			</>) : (<div style={gs.leftCol}>
				<BackButton back={() => setMenuView(undefined)}/>
				{menuView === 'settings' && (<SettingsMenu />)}
				{menuView === 'acknowledgement' && (<AcknowledgementMenu />)}
				{menuView === 'contact' && (<ContactMenu />)}
			</div>)}
		</div>
	</>)
}

const s = createStyles({
	container: {
		height: 'calc(100% - 3rem)',
		backgroundColor: colors.primary,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '1rem',
		paddingTop: '2rem',
	},
	background: {
		width: '100%',
		height: '100%',
		position: 'fixed',
		top: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	icon: {
		width: '2rem',
		height: '2rem',
		marginRight: '1rem',
		backgroundColor: 'white',
		borderRadius: '50%',
	},
	title: {
		color: 'white',
	},
	item: {
		...gs.left,
		width: 'calc(100% - 1rem)',
		borderRadius: '0.8rem',
		padding: '0.5rem',
		marginBottom: '0.8rem',
	},
	itemIcon: {
		width: '1.2rem',
		height: '1.2rem',
		marginRight: '0.5rem',
	},
	itemTitle: {
		color: 'white',
		fontSize: '1rem',
	},
	bottomMenu: {
		borderTop: `1px solid ${colors.primaryLight}`,
		padding: '0.5rem',
		paddingBottom: 0,
		width: 'calc(100% - 1rem)',
	},
	topMenu: {
		width: 'calc(100% - 1rem)',
		marginTop: '4rem',
	}
})
