import { CSSProperties } from "react";
import { createStyles, gs } from "../styles"
import { FillImage } from "./FillImage";

export const BlueRoundedButton = (props: {
	onClick: () => void;
	icon: string;
	title: string;
	textStyle?: CSSProperties
	className?: string
}) => {
	let className = 'primary-button'
	if (props.className) {
		className += ' ' + props.className
	}
	return (
		<button className={className} style={s.button} onClick={props.onClick}>
			<FillImage src={props.icon} alt={props.title} />
			<p style={{ ...gs.buttonText, ...props.textStyle }}>{props.title}</p>
		</button>
	)
}

const s = createStyles({
	button: {
		...gs.left,
		width: 'calc(100% - 1rem)',
		borderRadius: '0.8rem',
		padding: '0.5rem',
		marginBottom: '0.8rem',
	},
})
