import { createContext } from 'react'

const views = ['loading', 'select', 'mode1', 'mode2', 'view_directory', 'mode1_directory', 'mode2_directory'] as const;
export type View = typeof views[number];

interface DisplayValueType {
	mobile: boolean,
	setMobile: (newMobile: boolean) => void;
	menuOpen: boolean,
	setMenuOpen: (newMenuOpen: boolean) => void;
	view: View,
	setView: (newView: View) => void;
}

export const DisplayContext = createContext<DisplayValueType>({
	mobile: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setMobile: (newMobile: boolean) => (void 0),
	menuOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setMenuOpen: (newMenuOpen: boolean) => (void 0),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	view: 'loading',
	setView: (newView: View) => (void 0)
})
