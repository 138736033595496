import { gs } from "../../styles"
import { FillImage } from "../FillImage"

const content = [
`Funding for this project was provided by the 
Social Sciences and Humanities Research Council 
of Canada (SSHRC).`,
`This project is a partnership between the Lead To 
Include research team at Wilfrid Laurier 
University and Smart Waterloo Region Innovation 
Lab. For information, please contact Dr. Steve 
Sider (ssider@wlu.ca).`
]

export const AcknowledgementMenu = () => {
	return (<>
		<div style={{ ...gs.left, margin: '0.5rem' }}>
			<FillImage style={{ height: '1.3rem', width: '1.3rem', marginRight: '0.5rem' }}src='/icons/menu-acknowledgement.png' alt='Settings' />
			<h5 style={gs.menuTitleText}>Acknowledgement</h5>
		</div>
		<div style={{ margin: '0.5rem' }}>
			{content.map((c, i) => (
				<p style={gs.menuContentText}>{c}</p>
			))}
		</div>
	</>)
}
