export const API_URL = 'https://api.laurier-swril.com'
// export const API_URL = 'http://localhost:8000'


// Function to convert FormData into URL-encoded string
export const formToUrlEncoded = (formData: FormData) => {
    const entries = formData.entries();
    const pairs = [];
    let entry = entries.next();
    while (!entry.done) {
        const [key, value] = entry.value;
        pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value as (string | number | boolean)));
        entry = entries.next();
    }
    return pairs.join('&');
}


