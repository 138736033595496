import { FillImage } from "../components/FillImage"
import { Header } from "../components/Header"
import { useDisplay } from "../hooks"
import { colors, createStyles, gs} from "../styles"

export const SelectModeView = () => {
	const { setView } = useDisplay()
	return (
		<div style={s.container}>
			<Header />
			<div style={s.content}>
				<h3 style={s.welcome}>Welcome to PrincipalBOT</h3>
				<p style={s.subTitle}>Enhancing the educational experience, for principals and emerging leaders</p>
				<p style={s.selectPrompt}>Please select a mode to continue</p>
				<div style={s.modeOverview}>
					<FillImage src='icons/message.png' alt="Live AI Assistant" style={s.icon} />
					<div>
						<h5 style={s.modeTitle}>Mode 1: Live AI Assistant</h5>
						<p style={s.modeDescription}>Provides instant recommendations and answers questions whenever required.</p>
					</div>
				</div>
				<button className={'primary-button mode1-start-button'} style={s.startModeButton} onClick={() => setView('mode1')}>Start Mode 1</button>
				<div style={s.modeOverview}>
					<FillImage src='icons/book.png' alt="Live AI Assistant" style={s.icon} />
					<div>
						<h5 style={s.modeTitle}>Mode 2: Training and Practice</h5>
						<p style={s.modeDescription}>Intuitive study assistant simplifying concepts and allowing for practice 24/7.</p>
					</div>
				</div>
				<button className={'primary-button mode2-start-button'} style={s.startModeButton} onClick={() => setView('mode2')}>Start Mode 2</button>
			</div>
		</div>
	)
}
  
const s = createStyles({
	container: {
		...gs.column,
		width: '100%',
		height: '100%',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		padding: '2rem 1rem',
		alignItems: 'center',
		backgroundColor: colors.background,
		width: 'calc(100% - 2rem)',
		height: 'calc(100% - 4rem)',
		borderTop: `1px solid ${colors.lightGrey}`,
		overflowY: 'scroll',
	},
	welcome: {
		fontSize: '1.5rem',
		marginBottom: '1rem',
	},
	subTitle: {
		fontSize: '1.2rem',
		marginBottom: '1rem',
		textAlign: 'center',
		color: colors.darkGrey,
	},
	selectPrompt: {
		fontSize: '1rem',
		color: colors.primary,
		fontWeight: 'bold',
	},
	icon: {
		width: '1.6rem',
		minWidth: '1.6rem',
		height: '1.6rem',
		padding: '0.8rem',
		borderRadius: '0.5rem',
		backgroundColor: colors.iconBackground,
		marginRight: '1rem',
	},
	modeOverview: {
		display: 'flex',
		marginTop: '2rem',
		marginBottom: '1rem',
	},
	startModeButton: {
		width: 'min(100%, 20rem)',
		height: '3rem',
		backgroundColor: colors.primary,
		color: 'white',
		borderRadius: '1rem',
		fontSize: '1rem',
	},
	modeTitle: {
		fontSize: '1rem',
		fontWeight: 500,
	},
	modeDescription: {
		fontSize: '0.85rem',
		fontWeight: 300,
		color: colors.darkGrey,
	},
})
