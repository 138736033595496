import { useEffect, useRef } from 'react'
import { createStyles } from '../../styles'
import { Message } from './Message'
import { Mode1MessageType, Mode2MessageType } from '../../types/messages'
import { useMode1Data, useMode2Data } from '../../hooks'

export const Messages = (props: {
	messages: (Mode1MessageType | Mode2MessageType)[]
	mode: 1 | 2
}): JSX.Element => {
	const divRef = useRef<HTMLDivElement | null>(null)
	const mode1Data = useMode1Data()
	const mode2Data = useMode2Data()
	const data = props.mode === 1 ? mode1Data : mode2Data
	const { awaitingResponse } = data;
	useEffect(() => {
		if (divRef.current) divRef.current.scrollTop = divRef.current.scrollHeight
	}, [props.messages])
	return (
		<div ref={divRef} style={s.container}>
			{props.messages.map((message, index) => {
				return (
					<Message 
						key={index} 
						message={message} 
						mode={props.mode}
					/>
				)
			})}
			{awaitingResponse && <Message loading mode={props.mode}/>}
		</div>
	)
}

const s = createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'scroll',
		height: '100%',
		// paddingTop: '1rem',
		width: 'calc(100% - 2rem)',
		padding: '1rem',
	},
})


