import { DataResponse, DataResponseType } from "../types/data"
import { SessionStorage } from "../utils/sessionStorage"
import { API_URL } from "./api"

export const sendFeedback = async (
	messageId: number,
	feedback: boolean,
	chatID: string
): Promise<DataResponseType<string>> => {
	try {
		const formData = new FormData()
		formData.append('message_id', messageId.toString())
		formData.append('feedback', feedback ? 'good' : 'bad')
		formData.append('chat_id', chatID)
		let url = API_URL + '/feedback'

		const response = await fetch(url, {
			method: 'POST',
			body: formData,
			headers: {
				'Authorization': `Bearer ${SessionStorage.get('token')}`
			}
		})
		if (response.ok) {
			const result = await response.json() as { response: string }
			return DataResponse.success(result.response)
		} else {
			return DataResponse.warning('Failed to submit feedback.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to submit feedback.')
	}
}
