import { DataResponse, DataResponseType } from "../types/data"
import { API_URL } from "./api"

export const getAuthorization = async (password: string): Promise<DataResponseType<string>> => {
	try {
		const formData = new FormData()
		formData.append('password', password)
		const url = API_URL + '/authenticate'
		const response = await fetch(url, {
			method: 'POST',
			body: formData
		})
		if (response.ok) {
			const result = await response.json() as {token: string}
			return DataResponse.success(result.token)
		} else {
			return DataResponse.warning('Failed to get auth.')
		}
	} catch (e) {
		return DataResponse.warning('Failed to get auth.')
	}
}
